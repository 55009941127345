import * as React from "react";
import { container , feedback, styledContainer, dates,subtitle} from "./GenerateGroupLink.module.scss";

export const Container = (props: any) => {
    return (<div className={container}>{props.children}</div>);
};
export const Feedback = (props: any) => {
    return (<div className={feedback}>{props.children}</div>);
};
export const StyledContainer = (props: any) => {
    return (<div className={styledContainer}>{props.children}</div>);
};

export const Dates = (props: any) => {
    return (<div className={dates}>{props.children}</div>);
};
export const Subtitle = (props: any) => {
    return (<div className={subtitle}>{props.children}</div>);
};



