import React from "react";
import { inputWrapper, searchResults, sectionContainer } from "./HotelAutoComplete.module.scss";

export const InputWrapper = (props: any) => {
    return (<div className={inputWrapper}>{props.children}</div>);
};

export const SectionContainer = (props: any) => {
    return (<div className={sectionContainer}>{props.children}</div>);
};

export const SearchResults = (props: any) => {
    return (<div className={searchResults}>{props.children}</div>);
};